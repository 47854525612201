/* eslint-disable jsx-a11y/anchor-is-valid */
import { BASEURL , SITE_BASEURL} from "../Config/Constant";

function Footer() {
  
  return (
<>

    <section id="footer" className="position-relative">
        <div className="pattern-overlay pattern-left position-absolute">
            <img src={`${SITE_BASEURL}images/leaf-img-pattern-left.png`}  alt="pattern" />
        </div>
        <div className="pattern-overlay pattern-right position-absolute">
            <img src={`${SITE_BASEURL}images/leaf-img-pattern-right.png`} alt="pattern"/>
        </div>

        <div className="container footer-container py-5">
            <footer className="row my-5 py-5">
{/*  
                <div className="col-6 col-md-2 ">
                    <ul className="nav flex-column">
                        <li className="nav-item mb-2"><a href="#" className="footer-link ">Twitter</a></li>
                        <li className="nav-item mb-2"><a href="#" className="footer-link ">Facebook</a></li>
                        <li className="nav-item mb-2"><a href="#" className="footer-link ">Instagram</a></li>
                        <li className="nav-item mb-2"><a href="#" className="footer-link ">Linkedin </a></li>
                    </ul>
                </div>

                <div className="col-6 col-md-2 ">
                    <ul className="nav flex-column">
                        <li className="nav-item mb-2"><a href="#" className="footer-link">About</a></li>
                        <li className="nav-item mb-2"><a href="#" className="footer-link">Contact</a></li>
                        <li className="nav-item mb-2"><a href="#" className="footer-link">Blog</a></li>
                        <li className="nav-item mb-2"><a href="#" className="footer-link">Policy</a></li>
                        <li className="nav-item mb-2"><a href="#" className="footer-link">Terms</a></li>
                    </ul>
                </div>

                <div className="col-6 col-md-2 ">
                    <ul className="nav flex-column">
                        <li className="nav-item mb-2"><a href="#" className="footer-link">Spirituality</a></li>
                        <li className="nav-item mb-2"><a href="#" className="footer-link">Life</a></li>
                        <li className="nav-item mb-2"><a href="#" className="footer-link">Relationships</a></li>
                        <li className="nav-item mb-2"><a href="#" className="footer-link">Travel</a></li>
                        <li className="nav-item mb-2"><a href="#" className="footer-link">Health</a></li>
                    </ul>
                </div>

                <div className="col-6 col-md-2 ">
                    <ul className="nav flex-column">
                        <li className="nav-item mb-2"><a href="#" className="footer-link">Spirituality</a></li>
                        <li className="nav-item mb-2"><a href="#" className="footer-link">Life</a></li>
                        <li className="nav-item mb-2"><a href="#" className="footer-link">Relationships</a></li>
                        <li className="nav-item mb-2"><a href="#" className="footer-link">Travel</a></li>
                        <li className="nav-item mb-2"><a href="#" className="footer-link">Health</a></li>
                    </ul>
                </div>

*/}

                <div className=" col-md-4 mt-5 mt-md-0 text-md-end">
                { /*
                    <p className="m-0"> <b>hello@templatesjungle.com</b> </p>
                    <p className="m-0">15Th Street Avenue, New York, USA</p>
                    <p className="m-0">011-554-8798-6556</p>


 <div className=" mt-3 d-flex justify-content-md-end">
                        <a href="#" className="social-link active ps-3" target="_blank"><iconify-icon className="social-icon"
                                icon="mdi:facebook"></iconify-icon></a>
                        <a href="#" className="social-link ps-3" target="_blank"><iconify-icon className="social-icon"
                                icon="mdi:instagram"></iconify-icon></a>
                        <a href="#" className="social-link ps-3" target="_blank"><iconify-icon className="social-icon"
                                icon="mdi:twitter"></iconify-icon></a>
                        <a href="#" className="social-link ps-3" target="_blank"><iconify-icon className="social-icon"
                                icon="mdi:linkedin"></iconify-icon></a>
                        <a href="#" className="social-link ps-3" target="_blank"><iconify-icon className="social-icon"
                                icon="mdi:youtube"></iconify-icon></a>

                    </div>
*/}
                   

                </div>

            </footer>

            <footer className="d-flex flex-wrap justify-content-between align-items-center border-top"></footer>

            <footer className="py-2 pt-4">
                <div className="d-flex flex-column align-items-center mt-3">
                    <p className="mb-0">© 2023 Food and me. All rights reserved.</p>
                    
                </div>
            </footer>

        </div>
    </section>

</>
  )

}

export default Footer;

