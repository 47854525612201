/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import axios from 'axios';
import { useForm } from "react-hook-form";
import { BASEURL } from "../Config/Constant";



const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

function Ctasection() {

    const {register,handleSubmit, reset, 
                formState: { errors }
            } = useForm();

    const onSubmit = (data, e) =>{
        
        const  newUrl = BASEURL+ 'notification';
        


//////////////
        axios
        .post(newUrl, data, {
        headers: {
            "Content-Type": "application/json",
        },
        })
        .then((res) => {
           reset(data);
        })
        .catch((err) => {
        console.log(err);
        });
        ////////////

    } 
      const onError = (errors, e) => console.log(errors, e)

  return (
<>
    <section id="cta">
        <div className="container py-5">
            <div className="row align-items-center g-5 my-2 py-5">
                <div className="col-12 col-md-10 col-lg-6 offset-lg-1">
                    <h2 className=" display-3 my-3">Stay connect with your queries</h2>
                    <p className="cta-paragraph mb-5">We try to best suite reply you</p>
                </div>
                <div className="col-12 col-md-10 col-lg-4 ms-lg-4">
                    <form onSubmit={handleSubmit(onSubmit, onError)} id = "query_form">
                        <div className="form-group">
                            <input type="text" name="name" placeholder="Name" className="form-control p-3 " {...register("name", { required: true, pattern: /^[a-zA-Z0-9]+$/i })}/>
                            {errors.name && <span>Enter the name</span>}
                            <input type="text" name="email" placeholder="Email" className="form-control p-3  my-2"
                                required="email" {...register("email", { required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i })}/>
                              {errors.email && <span>Invalid email</span>}
                            <textarea 
                            validation={{
                                required: {
                                  value: true,
                                  message: 'required',
                                },
                                minLength: {
                                  value: 6,
                                  message: 'min 6 characters',
                                },
                              }}
                            
                            
                            rows="4" cols="50" name="message" {...register("message", { required: true, pattern: /^[a-zA-Z0-9]+$/i })} placeholder="Query" className="form-control p-3  my-2"></textarea>    

                               {errors.message && <span>Query required</span>}
                            
                            <div className="d-grid gap-2">
                                <button type="submit" className="btn btn-primary text-uppercase p-3" >Subscribe</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>

</>
  )

}

export default Ctasection;

