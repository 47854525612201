/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState} from 'react';
import axios from 'axios';
import { BASEURL } from "../Config/Constant";


function Featurecard() {
  
    const [loading, setLoading] = useState(true);
    const [bdata, setData] = useState([])
  

    useEffect(() => {

        
  
        const  newUrl = BASEURL+ 'posts/home-featurecart';
         
      
          const fetchData = async () =>{
            setLoading(true);
            try {
              const response = await axios.get(newUrl);
              setData(response.data.posts);
              
            } catch (error) {
              console.error(error.message);
            }
            setLoading(false);
          }
      
          fetchData();
        }, []);



  return (
<>

    <section id="featured" className="my-5 py-5">
        <div className=" container d-md-flex justify-content-between" style={{backgroundColor:'#f4f2f2',paddingTop:'11px'}}>
            <p className="feature-title">Featured Diets</p>
{

/*
            <div className="mt-5 mt-md-0 mb-5">
                <button className="filter-button active text-uppercase px-3 mb-3 " data-filter="*">All</button>
                <button className="filter-button text-uppercase px-3 mb-3 " data-filter=".love">love</button>
                <button className="filter-button text-uppercase px-3 mb-3 " data-filter=".yoga">yoga</button>
                <button className="filter-button text-uppercase px-3 mb-3 " data-filter=".recipes">recipes</button>
                <button className="filter-button text-uppercase px-3 mb-3 " data-filter=".naturalherbs">natural
                    herbs</button>
            </div>
  */}
        </div>

        <div className="container my-4">
            <div className=" row isotope-container1 " style={{height:"-1px!important"}}>
             
            {
                bdata.map((data)=>{
                return(
                    <div key={ data._id } className="item love col-md-6">
                <div className="featured-post py-2 pe-5">
                    <span className="blog-date text-uppercase">{data.title}</span>
                    <p className=""><a href onClick={(r) => {r.preventDefault();}} className="blog-link">{data.shortTitle}</a></p>
                </div>
                <hr className="my-4" />
            </div>
                )     
                
                })}

            </div>
        </div>
    </section>
</>
  )

}

export default Featurecard;

