/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState} from 'react';
import { BrowserRouter as Router,useParams } from "react-router-dom";
import axios from 'axios';
import { BASEURL } from "../Config/Constant";


function Postcard() {
  
    const [loading, setLoading] = useState(true);
    const [bdata, setData] = useState([])
    const { id }  = useParams(); 
    useEffect(() => {
  

        const  newUrl = BASEURL+ 'posts/'+id;
         
      
          const fetchData = async () =>{
            setLoading(true);
            try {
              const response = await axios.get(newUrl);
              setData(response.data.post);
              
            } catch (error) {
              console.error(error.message);
            }
            setLoading(false);
          }
      
          fetchData();
        }, []);

   console.log(bdata);

  return (
<>

    <section id="featured" className="my-5 py-5">
        <div className=" container d-md-flex justify-content-between" style={{backgroundColor:'#f4f2f2',paddingTop:'11px'}}>
            <p className="feature-title">{bdata.title}</p>
{

/*
            <div className="mt-5 mt-md-0 mb-5">
                <button className="filter-button active text-uppercase px-3 mb-3 " data-filter="*">All</button>
                <button className="filter-button text-uppercase px-3 mb-3 " data-filter=".love">love</button>
                <button className="filter-button text-uppercase px-3 mb-3 " data-filter=".yoga">yoga</button>
                <button className="filter-button text-uppercase px-3 mb-3 " data-filter=".recipes">recipes</button>
                <button className="filter-button text-uppercase px-3 mb-3 " data-filter=".naturalherbs">natural
                    herbs</button>
            </div>
  */
  }


        </div>

        <div class="container my-5 py-5">
          <div class="row">
                <div class="col-md-12 col-lg-12" style={{border:'solid 0px red',marginTop:'40px'}}>
                    <div class="blog-post pb-3">
                    <div dangerouslySetInnerHTML={{ __html: bdata.description }}></div>
                    </div>
                </div>
          </div>
        </div>

    </section>
</>
  )

}

export default Postcard;

