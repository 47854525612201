/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { BrowserRouter as Router , Routes, Route } from "react-router-dom";
import Blocksection from './Components/Blocksection';
import Blogcard from "./Components/Blogcard";
import Ctasection from './Components/Ctasection';
import Featurecard from './Components/Featurecard'; //./Components/Featurecard';
import Footer from './Components/Footer';
import Search from './Components/Search';
import Header from './Components/Header';
import Banner from './Components/Banner';
import Postcard from './Components/Postcard';

function App() {

  
  return (
<>

    <Search />
    <Header />
    
    <Router>
        <Routes>
                <Route exact path='/' element={
                <>
                <Banner />
                <Blogcard /> 
                <Featurecard />
                <Blocksection />
                </>
                }>
                </Route>
                <Route exact path='/info/:id' element={
                <>
                <Banner />
                <Postcard />
                </>
                }>
                </Route>

                <Route exact path='/deficiencies-and-healing/:id' element={
                <>
                <Banner />
                <Blogcard /> 
                </>
                }>
                </Route>
                <Route exact path='/contact' element={
                <>
                <Banner />
                </>
                }>
                </Route>
               
        </Routes>
        </Router>
        <Ctasection />
    <Footer />
    
    
</>
  );
}

export default App;
