/* eslint-disable jsx-a11y/anchor-is-valid */
import { BrowserRouter as Router,Routes, Route, Link } from 'react-router-dom';
import { BASEURL ,SITE_BASEURL} from "../Config/Constant";

function Header() {
  
  return (
<>

  
<nav id="header-nav" className="navbar navbar-expand-lg container-fluid py-4 px-5 position-fixed">
        <Router>
        <Link reloadDocument={true} to="/">
        <img src={`${SITE_BASEURL}images/lg.png`}  style={{width:"40%"}} />
        </Link>
        
        </Router>
        <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar"
            aria-controls="offcanvasNavbar">
            <span className="navbar-toggler-icon"></span>
        </button>
        <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
            <div className="offcanvas-header">
                <h5 className="offcanvas-title" id="offcanvasNavbarLabel">Menu</h5>
                <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas"
                    aria-label="Close"></button>
            </div>
            <div className="offcanvas-body">
            <Router>
                <ul className="navbar-nav align-items-center justify-content-end flex-grow-1 pe-3">
                    
                   
                    <li className="nav-item"><Link reloadDocument={true} className="nav-link pe-lg-5" to="/">Food</Link></li>
                    {/* 
                    <li className="nav-item"><Link reloadDocument={true} className="nav-link pe-lg-5" to="/deficiencies-and-healing">Deficiencies and Healing</Link></li>
                    <li className="nav-item"><Link reloadDocument={true} className="nav-link pe-lg-5" to="/qna">QNA</Link></li>
                    <li className="nav-item"><Link reloadDocument={true} className="nav-link pe-lg-5" to="/contact">Contact</Link></li>
                    
                    <li className="nav-item search-item ">
                        <a href="#" className="search-button">
                            <svg className="search">
                                <use xlinkHref="#search"></use> 
                            </svg>
                        </a>
                    </li>
                    */}
                </ul>
                </Router>
    

            </div>
        </div>
    </nav>

</>
  )

}

export default Header;

