/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState} from 'react';
import axios from 'axios';
import { BASEURL ,SITE_BASEURL} from "../Config/Constant";
function Banner() {
  ///  Carbs , proties , fats , minerals, vit, water, 
  const [loading, setLoading] = useState(true);
  const [bdata, setData] = useState({})
  
   
  useEffect(() => {
    let varBanner;
    if(window.location.href.indexOf("info") > -1) {
          const urlHeader = window.location.href;
          const ar = urlHeader.split('/');
          console.log(ar[ar.length-1]);
          varBanner = ar[ar.length-1];
     }else{
          varBanner = 'home';
     }  
  
  const  newUrl = BASEURL+ 'banner/'+varBanner;
   

    const fetchData = async () =>{
      setLoading(true);
      try {
        const response = await axios.get(newUrl);
        setData(response.data.data);
 
      } catch (error) {
        console.error(error.message);
      }
      setLoading(false);
    }

    fetchData();
  }, []);
  return (
    
<>

<section id="hero"  className="padding-2xlarge jarallax imagey">
<div className="container banner-content 123{bdata.title}123">
    <div>
        <p className="hero-paragraph mt-3 ftn">{bdata.description}</p>
    </div>
</div> 
<div>
 
<img src={`${SITE_BASEURL}images/${bdata.bimage}`} style={{ height:"70%!important"}}  alt="banner" className="imageJ jarallax-img"/>
</div>

</section>

</>
  )

}

export default Banner;

