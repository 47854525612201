/* eslint-disable jsx-a11y/anchor-is-valid */

function Search() {
  
  return (
<>

<div className="search-popup">
        <div className="search-popup-container">

            <form role="search" method="get" className="search-form" action="">
                <input type="search" id="search-form" className="search-field" placeholder="Type and press enter" defaultValue=""
                    name="s" />
                <button type="submit" className="search-submit"><svg className="search">
                        <use xlinkHref="#search"></use>
                    </svg></button>
            </form>

            <h5 className="cat-list-title">Browse Categories</h5>

            <ul className="cat-list">
                <li className="cat-list-item">
                    <a href="#" title="Self Love">Self Love</a>
                </li>
                <li className="cat-list-item">
                    <a href="#" title="Relationships">Relationships</a>
                </li>
                <li className="cat-list-item">
                    <a href="#" title="Skin Care">Skin Care</a>
                </li>
                <li className="cat-list-item">
                    <a href="#" title="Health">Health</a>
                </li>
                <li className="cat-list-item">
                    <a href="#" title="Spa">Spa</a>
                </li>
                <li className="cat-list-item">
                    <a href="#" title="Friendship">Friendship</a>
                </li>
                <li className="cat-list-item">
                    <a href="#" title="Healing">Healing</a>
                </li>
            </ul>

        </div>
    </div>

</>
  )

}

export default Search;

