/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState} from 'react';
import axios from 'axios';
import { BASEURL , SITE_BASEURL} from "../Config/Constant";

function Blogcard() {

    const [loading, setLoading] = useState(true);
    const [bdata, setData] = useState([])
  

    useEffect(() => {

        
  
        const  newUrl = BASEURL+ 'posts/home-blog';
         
      
          const fetchData = async () =>{
            setLoading(true);
            try {
              const response = await axios.get(newUrl);
              setData(response.data.posts);
              
            } catch (error) {
              console.error(error.message);
            }
            setLoading(false);
          }
      
          fetchData();
        }, []);

  
  return (
<>
<section id="blog-card">
    <div className="container my-5 py-5">
        <div className="row ">
            { bdata.map((data)=>{
                var d = new Date(data.createdAt);
                
                return (
                    <div key={data._id} className="col-md-6 col-lg-4">
                    <div className="blog-post pt-5 pb-3">
    
                        <div className="image-zoom">
                            <a href={`${SITE_BASEURL}info/${data._id}`} className="blog-img"><img src={`${SITE_BASEURL}images/${data.simage}`} alt=""
                                    className="img-fluid" /></a>
                        </div>
                        <div className="pt-4">
                            <span className="blog-date text-uppercase"><b>{data.category}</b> - { d.toDateString() }</span>
                        </div>
                        <div className="">
                            <h3 className="py-3"><a href={`${SITE_BASEURL}info/${data._id}`} className="blog-link">{data.title} </a></h3>
                            <p className="pb-3">{data.shortTitle}
                            </p>
                        </div>
                        <div className="">
                            <a href={`${SITE_BASEURL}info/${data._id}`}
                                className="btn btn-outline-primary btn-arrow btn-pill btn-outline-light position-relative">
                                <span className="py-2 px-4">Read more</span>
                                <svg className="arrow-icon" width="18" height="20">
                                    <use xlinkHref="#arrow-icon"></use>
                                </svg>
                            </a>
                        </div>
                    </div>
                </div>
    
                )
            })}
          
        </div>
    </div>
</section>
</>
  )

}

export default Blogcard;

