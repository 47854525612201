/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useEffect, useState} from 'react';
import axios from 'axios';
import { BASEURL ,SITE_BASEURL} from "../Config/Constant";


function Blocksection() {

    const [loading, setLoading] = useState(true);
    const [bdata, setData] = useState([])
  

    useEffect(() => {

        
  
        const  newUrl = BASEURL+ 'posts/block-section';
         
      
          const fetchData = async () =>{
            setLoading(true);
            try {
              const response = await axios.get(newUrl);
              setData(response.data.posts);
              
            } catch (error) {
              console.error(error.message);
            }
            setLoading(false);
          }
      
          fetchData();
        }, []);

        
    
  return (
<>
    <section id="blog-block">
        <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-4 row-cols-xl-5 g-0 ">
            
        { bdata.map(
            (data)=>{
            return (
                <figure className="blog-block-content image-zoom m-0">
                <a href={`${SITE_BASEURL}info/${data._id}`} className=" position-relative">
                    <img className="blog-block-img img-fluid position-relative" src={`${SITE_BASEURL}${data.simage}`} alt=""/>
                    <div className="header-overlay position-absolute d-flex justify-content-center">
                        <h5 className="blog-block-heading p-5">{data.shortTitle}</h5>
                    </div>
                </a>
            </figure>
            )
        }
        )
        
        }
            
           
        </div>


    </section>

</>
  )

}

export default Blocksection;


